import Nav from "components/layout/nav";
import Footer from "components/layout/footer";

import PageNotFound from "./components/pageNotFound";

const Page404 = () => {
	return (
		<>
			<Nav isBgWhite={true} />

			<PageNotFound />

			<Footer />
		</>
	);
};

export default Page404;
