import { useContext } from 'react';
import { NotificationContext } from 'providers/notificationProvider';

import axios from 'axios';


const HomePageApi = () => {

	const { displayNotification } = useContext(NotificationContext);

    const getHomeData = async (locale: string) => {
        const result = await axios.get(process.env.REACT_APP_STRAPI_API_URL + `/home-page?locale=${locale}&populate=seo.metaImage,seo.metaSocial.image,detail.banner_video,awards.logo`)
        .then(response => {
            return response?.data?.data?.attributes;
        }).catch(error => {
            displayNotification('Something Went Wrong Fetching Home Page Data, Please Try Again !', 'error');
            console.error("[Error - API] Error calling getHomeData", error);
        });
        return result;
    }

	return {
		getHomeData: getHomeData
	};
}

export default HomePageApi;